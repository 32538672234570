import { computed, ref } from 'vue';
import useAxios from '../useAxios';
import { BRANDLIFT_API } from 'constants';
import useBrandliftBenchmarksCategories from './useBrandliftBenchmarksCategories';
import useBrandliftBenchmarksColumns from './useBrandliftBenchmarksColumns';
import { calculateWeightedValue, calculateSummaryBenchmark } from './useBrandliftBenchmarksHelpers';

const { categoryNameByKey } = useBrandliftBenchmarksCategories();
const { numberColumns } = useBrandliftBenchmarksColumns();

const loading = ref(false);
const rawBrandliftBenchmarks = ref([]);
const brandliftBenchmarkFilters = ref({});

const useBrandliftBenchmarks = () => {
  function setBenchmarks(benchmarks) {
    rawBrandliftBenchmarks.value = benchmarks;
  }

  const { fetcher: loadFetcher } = useAxios({ initialValue: [] });
  const { fetcher: deleteFetcher } = useAxios();
  const { fetcher: exportFetcher } = useAxios();
  const { fetcher: saveFetcher } = useAxios();
  const { fetcher: uploadFetcher } = useAxios();

  async function loadBrandliftBenchmarks(categories, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries(queryParams);

    if (paramItems.length) {
      query = `&${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    let data;
    try {
      loading.value = true;
      const categoriesToFetch = Array.isArray(categories) ? categories : [categories];
      data = await Promise.all(
        categoriesToFetch.map(category =>
          loadFetcher({
            method: 'GET',
            url: `${BRANDLIFT_API}/brandliftBenchmarks?categories=${category}${query}`
          })
        )
      ).then(res => {
        return categoriesToFetch.map((categoryKey, index) => {
          const benchmarks = res[index].filter(item => !!item.participants && !!item.year);

          return {
            key: categoryKey,
            _rowType: 'category',
            brand: `${categoryNameByKey.value[categoryKey]} Benchmark`,
            category: `${categoryNameByKey.value[categoryKey]} Benchmark`,
            children: benchmarks.map(item => ({ ...item, _rowType: 'benchmark' }))
          };
        });
      });
    } catch (err) {
      console.error('failed to fetch benchmarks');
    }
    if (data) setBenchmarks(data);

    loading.value = false;
  }

  function deleteBrandliftBenchmarks(payload) {
    return deleteFetcher({
      method: 'DELETE',
      url: `${BRANDLIFT_API}/brandliftBenchmarks`,
      requestBody: payload
    });
  }

  function exportBrandliftBenchmarks(categories, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries(queryParams);

    if (paramItems.length) {
      query = `&${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    return exportFetcher({
      method: 'GET',
      url: `${BRANDLIFT_API}/brandliftBenchmarks/export?categories=${
        Array.isArray(categories) ? categories.join('||') : categories
      }${query}`
    });
  }

  function saveBrandliftBenchmark(payload) {
    return saveFetcher({
      method: 'POST',
      url: `${BRANDLIFT_API}/brandliftBenchmark`,
      requestBody: payload
    });
  }

  function uploadBrandliftBenchmark(filename) {
    return uploadFetcher({
      method: 'GET',
      url: `${BRANDLIFT_API}/upload-url?fileName=${filename}`
    });
  }

  function setBrandliftBenchmarkFilters(filters) {
    brandliftBenchmarkFilters.value = filters;
  }

  function clearFilters() {
    brandliftBenchmarkFilters.value = {};
  }

  const brandliftBenchmarks = computed(() => {
    return rawBrandliftBenchmarks.value.map(category => {
      const filteredChildren = category.children.filter(item => {
        const filterKeys = Object.keys(brandliftBenchmarkFilters.value);
        if (!filterKeys || filterKeys.length === 0) return true;

        let includeInResults = true;
        filterKeys.forEach(key => {
          const filter = brandliftBenchmarkFilters.value[key];
          if (filter.type === 'number') {
            if (!item[key]) {
              includeInResults = false;
            }

            if (filter.value.from && filter.value.from >= +item[key].replace(',', '.')) {
              includeInResults = false;
            }

            if (filter.value.to && filter.value.to <= +item[key].replace(',', '.')) {
              includeInResults = false;
            }
          } else if (filter.type === 'boolean' && filter.value.length !== 0) {
            if (filter.value.indexOf(item[key].toString()) === -1) {
              includeInResults = false;
            }
          } else if (filter.type === 'text' && filter.value.length !== 0) {
            if (filter.value.toLowerCase().indexOf(item[key].toString().toLowerCase()) === -1) {
              includeInResults = false;
            }
          }
        });
        return includeInResults;
      });

      const summaryBenchmarkArrays = {};
      const weightsUsed = {};

      const processedChildren = filteredChildren.map(item => {
        const yearsBack = new Date().getFullYear() - +item.year;

        numberColumns.value.forEach(column => {
          if (!summaryBenchmarkArrays[column]) summaryBenchmarkArrays[column] = {};
          if (!summaryBenchmarkArrays[column][item.year]) summaryBenchmarkArrays[column][item.year] = [];
          if (!item[column]) return;

          if (column !== 'ais' && column !== 'participants') {
            const { weightedValue, weight } = calculateWeightedValue(item, column, yearsBack);
            weightsUsed[item.year] = weight;

            item[`${column}_weighted_value`] = `${item[column]} * ${weight} = ${
              Math.round((weightedValue + Number.EPSILON) * 10000) / 10000
            }`.replace('.', ',');

            summaryBenchmarkArrays[column][item.year].push(weightedValue);
          } else {
            summaryBenchmarkArrays[column][item.year].push(+`${item[column]}`.replace(',', '.'));
          }
        });

        return item;
      });

      const summaryBenchmark = calculateSummaryBenchmark(summaryBenchmarkArrays, weightsUsed);

      return {
        ...category,
        children: processedChildren,
        ...summaryBenchmark
      };
    });
  });

  return {
    loading,
    brandliftBenchmarks,
    loadBrandliftBenchmarks,
    deleteBrandliftBenchmarks,
    exportBrandliftBenchmarks,
    saveBrandliftBenchmark,
    uploadBrandliftBenchmark,
    setBrandliftBenchmarkFilters,
    brandliftBenchmarkFilters,
    clearFilters
  };
};

export default useBrandliftBenchmarks;
