<template>
  <Layout>
    <LayoutSider
      :trigger="null"
      collapsible
      v-model="collapsed"
      :width="siderWidth"
      :style="{ overflow: 'hidden', position: 'relative' }"
    >
      <Sidebar>
        <template #sidebar-top>
          <slot name="sidebar-top" />
        </template>

        <template #sidebar-bottom>
          <slot name="sidebar-bottom" />
        </template>
      </Sidebar>
    </LayoutSider>

    <Layout :style="{ height: '100vh' }">
      <LayoutHeader class="c-header__bar-top js-app-header" style="background: #fff">
        <ProProfs v-if="linkToDocsEnabled" />

        <Row>
          <Col :span="14">
            <Breadcrumbs v-if="!pageLoading" />
          </Col>

          <Col :span="10" class="u-flex u-flex--middle u-flex--justify-end">
            <div class="u-inline-block u-ml-x2 u-mr-x2">
              <NotificationCenter />
            </div>

            <a v-if="linkToDocsEnabled" class="u-mr-x2" :href="REPPUBLIKA_DOCS_LINK" target="_blank">
              <QuestionCircleOutlined
                :style="{
                  fontSize: '23px'
                }"
              />
            </a>

            <div
              v-if="hasCountrySwitcher && countries.length > 1"
              class="u-inline-block c-country-switcher u-mr-x2"
            >
              <DropdownNavigation
                :options="countries"
                :trigger="['click']"
                size="xs"
                keyProp="code"
                @select="switchCountry"
              >
                <template #dropdownButton>
                  <Button size="xs">
                    <img
                      :src="`${ASSET_PATH}img/icon-flag-${selectedCountry.code}.svg`"
                      class="u-inline-block u-align-middle u-mr"
                      width="20"
                    />

                    <span class="u-inline-block u-align-middle u-mr-x2">
                      <span class="u-color-text-helper u-weight-500 u-uppercase">
                        {{ selectedCountry.code }}
                      </span>
                    </span>

                    <DownOutlined :style="{ fontSize: '10px' }" />
                  </Button>
                </template>

                <template #dropdownItem="{ item: country }">
                  <img
                    :src="`${ASSET_PATH}img/icon-flag-${country.code}.svg`"
                    class="u-inline-block u-align-middle u-mr"
                    width="20"
                  />

                  <span class="u-inline-block u-align-middle">
                    {{ country.name }}
                    <span class="u-color-text-helper u-uppercase">– {{ country.code }}</span>
                  </span>
                </template>
              </DropdownNavigation>
            </div>

            <div class="c-app-logo u-inline-block">
              <router-link :to="{ name: 'startpage' }">
                <img :src="logoPath" />
              </router-link>
            </div>
          </Col>

          <Col v-if="!pageLoading" :span="24" class="c-header__toolbar">
            <slot name="toolbar" />
          </Col>
        </Row>
      </LayoutHeader>

      <LayoutContent :style="{ background: '#fff', overflow: 'scroll' }">
        <div id="page-wrapper" class="c-page-wrapper">
          <div class="c-page__content">
            <Fetcher :state="{ loading: pageLoading }">
              <slot></slot>
            </Fetcher>
          </div>
          <slot name="page-footer" />
        </div>
      </LayoutContent>
    </Layout>
  </Layout>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Breadcrumbs from '../generic/Breadcrumbs.vue';
import { NotificationCenter, Fetcher } from 'views';
import { clearCache, getTokenFromCookies, handleAsyncError } from 'helper';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { Action as GlobalAction, Mutation as GlobalMutation } from 'store/_global/types';
import {
  Button,
  Layout,
  LayoutContent,
  LayoutHeader,
  LayoutSider,
  Row,
  Col,
  DownOutlined,
  DropdownNavigation,
  QuestionCircleOutlined
} from 'reppublika_components';
import { APPS, ASSET_PATH } from 'utils/constants';
import { REPPUBLIKA_DOCS_LINK } from 'constants';
import ProProfs from './partials/ProProfs.vue';

export default {
  components: {
    Sidebar,
    Breadcrumbs,
    Button,
    Layout,
    LayoutContent,
    LayoutHeader,
    LayoutSider,
    Row,
    Col,
    DownOutlined,
    DropdownNavigation,
    NotificationCenter,
    Fetcher,
    QuestionCircleOutlined,
    ProProfs
  },
  setup() {
    return {
      ASSET_PATH,
      REPPUBLIKA_DOCS_LINK
    };
  },
  data() {
    return {
      collapsed: false,
      siderWidth: 280
    };
  },
  mounted() {
    if (!this.tooltips.length) {
      this.fetchHelpers();
    }
  },
  computed: {
    logoPath() {
      if (this.activeApp !== APPS.OEWA_RATINGS) {
        return `${this.ASSET_PATH}img/ReppublikaColor.svg`;
      }

      return `${this.ASSET_PATH}img/Oewa.svg`;
    },
    hasCountrySwitcher() {
      return this.$route.meta.hasCountrySwitcher;
    },
    linkToDocsEnabled() {
      return window.location.hostname !== 'dashboard.oewa.at';
    },
    ...mapState(['tooltips', 'showFilterMenu', 'activeApp', 'pageLoading', 'selectedCountryCode']),
    ...mapGetters(['countries', 'selectedCountry', 'currentUserModules'])
  },
  methods: {
    async switchCountry(countryCode) {
      const previousCountryCode = this.selectedCountryCode;
      const token = getTokenFromCookies();

      if (!token) {
        return this.$router.push({ name: 'login' });
      }

      this.setPageLoading(true);
      this.setSelectedCountryCode(countryCode);

      await clearCache();

      try {
        await this.checkTokens({ token, mustRefresh: true });

        if (!this.$route.params.country_code) return this.setPageLoading(false);

        const params = { country_code: countryCode };
        const routeResolve = this.$router.resolve({ to: this.$route, params });
        const noPermission = await this.handleNext({ to: routeResolve });

        if (noPermission?.name) {
          return this.$router.push({ name: noPermission.name });
        }

        await this.fetchHelpers();

        this.$router.push({ params });
      } catch (error) {
        handleAsyncError(error, 'Something went wrong. An error occurred while switching countries.');

        this.setSelectedCountryCode(previousCountryCode);
        this.$router.push({ name: 'login' }); // need to re-login to get new tokens
      }
    },
    ...mapActions({
      fetchHelpers: GlobalAction.FETCH_HELPERS,
      fetchUserInfo: GlobalAction.FETCH_USER_INFO,
      checkTokens: GlobalAction.CHECK_TOKENS,
      handleNext: GlobalAction.HANDLE_NEXT
    }),
    ...mapMutations({
      setSelectedCountryCode: GlobalMutation.SET_SELECTED_COUNTRY_CODE,
      setPageLoading: GlobalMutation.SET_PAGE_LOADING
    })
  }
};
</script>
