<script setup>
import { PROPROFS_WIDGET_API_KEY } from 'constants';

const isScriptLoaded = () => {
  return document.querySelector(`script[src*="helpiq_embed.js?api_key=${PROPROFS_WIDGET_API_KEY}"]`) !== null;
};

const initialiseProProfs = () => {
  if (!isScriptLoaded()) {
    const script = createScript();
    document.head.append(script);
  }
};

const createScript = () => {
  const script = document.createElement('script');
  script.src = `https://titoandfriends.helpdocsonline.com/helpiq_embed.js?api_key=${PROPROFS_WIDGET_API_KEY}&embed=true`;

  script.defer = true;

  return script;
};

if (!!PROPROFS_WIDGET_API_KEY) initialiseProProfs();
</script>

<template>
  <div></div>
</template>

<style lang="less">
#help-sticky-btn.embed_bar {
  background-color: #b36cc4 !important;
  padding: 15px 5px;
  border-radius: 0 5px 5px 0;
  right: 0;
  font-size: 16px;
  width: 35px;
}
#help-sticky-btn.embed_bar:hover {
  opacity: 0.8;
  color: white !important;
  cursor: pointer;
}
#embed_Sidebar .head-hide.embed_head_hide {
  top: 10px;
  right: 10px;
}
</style>
