export function calculateWeightedValue(item, column, yearsBack) {
  let weightedValue = +`${item[column]}`.replace(',', '.');
  let weight = 1;
  if (yearsBack >= 4 && yearsBack <= 7) {
    weightedValue = weightedValue * 0.75;
    weight = 0.75;
  } else if (yearsBack >= 8 && yearsBack <= 12) {
    weightedValue = weightedValue * 0.5;
    weight = 0.5;
  } else if (yearsBack >= 13 && yearsBack <= 17) {
    weightedValue = weightedValue * 0.25;
    weight = 0.25;
  } else if (yearsBack >= 18 || +item.year === 0) {
    weightedValue = weightedValue * 0.1;
    weight = 0.1;
  }

  return { weightedValue, weight };
}

export function calculateSummaryBenchmark(summaryBenchmarkArrays, weightsUsed) {
  const summaryBenchmark = {};
  const weightsPerYear = Object.values(weightsUsed);
  const yearsWithValues = Object.keys(weightsUsed);

  Object.keys(summaryBenchmarkArrays).forEach(column => {
    const averagePerYear = Object.keys(summaryBenchmarkArrays[column]).map(year => {
      const sum = summaryBenchmarkArrays[column][year].reduce((a, b) => a + b, 0);
      const avg = sum / summaryBenchmarkArrays[column][year].length || 0;
      return avg;
    });

    const averagePerYearSum = averagePerYear.reduce((a, b) => a + b, 0);

    const weightSum = weightsPerYear.reduce((a, b) => a + b, 0);
    const weightedAvg = averagePerYearSum / weightSum || 0;

    summaryBenchmark[column] = `${Math.round((weightedAvg + Number.EPSILON) * 100) / 100}`.replace('.', ',');

    // String(Math.round((weightedAvg + Number.EPSILON) * 10000) / 10000)
    let summary = `Value calculations:\n`;
    for (let i = 0; i < averagePerYear.length; i++) {
      summary += `Year ${yearsWithValues[i]}: ${(averagePerYear[i] / weightsPerYear[i]).toFixed(2)} * ${
        weightsPerYear[i]
      } = ${averagePerYear[i].toFixed(2)}\n`;
    }
    summary += `\nFinal weighted average: ${averagePerYearSum.toFixed(
      2
    )} / ${weightSum} = ${weightedAvg.toFixed(2)}`;

    summaryBenchmark[`${column}_weighted_value`] = summary;
  });

  return summaryBenchmark;
}
